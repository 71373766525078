import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { InternalAttachments } from '../../components/Attachments/Internal';
import { SupportingAttachments } from '../../components/Attachments/Supporting';
import { ExhibitsAttachments } from '../../components/Attachments/Exhibits';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const ContractDocumentAttachments = () => {
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const { disabled, form, initiateSneakyUpdate, project } = useContext(
        ProjectCreateV2FunctionsContext
    );

    if (contractPackageCompilerEnabled) {
        return (
            <Box>
                <Box sx={{ mb: 3 }}>
                    <ExhibitsAttachments
                        disabled={disabled}
                        form={form}
                        project={project}
                        triggerUpdate={initiateSneakyUpdate}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <SupportingAttachments
                    allowedReassignmentTypes={[attachmentTypesDict.INTERNAL]}
                    disabled={disabled}
                    form={form}
                    project={project}
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
            <Box sx={{ mb: 3 }}>
                <InternalAttachments
                    allowedReassignmentTypes={[attachmentTypesDict.OTHER]}
                    disabled={disabled}
                    form={form}
                    project={project}
                    standalone
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
        </Box>
    );
};
