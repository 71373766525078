import { pick, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { AttachmentRowItem } from './AttachmentRowItem';
import { AttachmentRowItemForm } from './AttachmentRowItemForm';
import { form, fields } from './AttachmentRowItemForm/constants';
import { ALL_APPENDICES } from '../../constants/attachments';
import { Attachable } from '../Forms/Attachable';
import { ProjectDetailTitle, SectionIntro } from '../GovApp/ProjectDetail';

import { getDndStyle } from '../../constants/styles';

const { BODY } = subsectionTypeNames;

const { ADDENDUM, OTHER, INTERNAL, PROJECT_DOCUMENT } = attachmentTypesDict;

/*
 * Used for displaying, editing, adding and removing on the review flow
 */
export class AttachmentTable extends PureComponent {
    static propTypes = {
        addAttachment: PropTypes.func,
        appendixIdsMap: PropTypes.object,
        attachments: PropTypes.array.isRequired,
        deleteAttachment: PropTypes.func,
        description: PropTypes.shape({
            [BODY]: PropTypes.object,
        }).isRequired,
        downloadPublicAttachment: PropTypes.func,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        showComments: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func,
        showProjectDocuments: PropTypes.bool,
        title: PropTypes.string,
        toggleEditAttachment: PropTypes.func,
        updateAttachment: PropTypes.func,
        sectionDescActions: PropTypes.object,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
    };

    static defaultProps = {
        appendixIdsMap: {},
        downloadPublicAttachment: undefined,
        isDocx: false,
        isEditable: false,
        isReadOnly: false,
        showComments: false,
        title: 'Attachments',
    };

    get projectAttachments() {
        const { attachments } = this.props;
        const filteredAttachments = attachments.filter((att) => {
            return !att.isHiddenByLogic && att.type !== ADDENDUM && att.type !== PROJECT_DOCUMENT;
        });

        return sortBy(filteredAttachments, [(o) => o.appendixId?.length, 'appendixId']);
    }

    get projectTitleProps() {
        const { projectSection, projectSubsectionsMap } = this.props;

        return {
            projectSection,
            projectSubsectionId: projectSubsectionsMap[BODY].id,
        };
    }

    uploadHandler = (attachmentType) => (fileData) => {
        const { addAttachment } = this.props;

        const { attachment, signedData } = fileData;

        addAttachment({
            appendixId: attachment.appendixId,
            bucket: signedData.bucket,
            filename: signedData.filename,
            path: signedData.key,
            title: attachment.title,
            type: attachmentType,
        });
    };

    deleteHandler = (attachmentId) => () => {
        const { deleteAttachment, showConfirmationSimpleModal } = this.props;

        showConfirmationSimpleModal(() => deleteAttachment(attachmentId), {
            btnText: 'Delete Attachment',
            text: 'Are you sure you want to delete this attachment?',
        });
    };

    toggleEditHandler = (attachmentId) => () => {
        const { toggleEditAttachment } = this.props;

        toggleEditAttachment(attachmentId);
    };

    updateHandler = (attachmentId) => (data) => {
        const { updateAttachment } = this.props;

        updateAttachment(attachmentId, data);
    };

    handleDragEnd = (result) => {
        const { updateAttachment } = this.props;
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            const appendices = this.projectAttachments.map((attach) => attach.appendixId);
            const attachmentsCopy = this.projectAttachments.slice();
            const attachmentToMove = attachmentsCopy.splice(originLocation, 1);

            attachmentsCopy.splice(newLocation, 0, ...attachmentToMove);
            attachmentsCopy.forEach((attachment, idx) =>
                updateAttachment(
                    attachment.id,
                    { ...attachment, appendixId: appendices[idx] },
                    { silenceNotifications: idx !== 0 }
                )
            );
        }
    };

    renderNoAttachments(attachmentTypes) {
        return (
            <div className="text-muted text-center">
                <i>No {attachmentTypes} were included</i>
            </div>
        );
    }

    renderAttachmentBlock(attachmentType, hideAppendixLetter) {
        const { isEditable, projectId } = this.props;

        if (!isEditable) {
            return null;
        }

        return (
            <div className="attachment-block">
                <Attachable
                    formKey={attachmentType}
                    hideAppendixLetter={hideAppendixLetter}
                    onUploadComplete={this.uploadHandler(attachmentType)}
                    projectId={projectId}
                />
            </div>
        );
    }

    renderTable(attachments, hideAppendixLetter) {
        const { appendixIdsMap, downloadPublicAttachment, isEditable, isReadOnly } = this.props;

        const availableAppendices = ALL_APPENDICES.map((letter) => {
            return {
                disabled: !!appendixIdsMap[letter],
                label: letter,
                value: letter,
            };
        });

        const AttachmentItems = attachments
            .filter((a) => a.type !== INTERNAL)
            .map((attachment, idx) => {
                if (attachment.showEditForm) {
                    return (
                        <li key={attachment.id}>
                            <AttachmentRowItemForm
                                appendixOptions={availableAppendices}
                                form={`${form}${attachment.id}`}
                                hideAppendixLetter={hideAppendixLetter}
                                initialValues={pick(attachment, fields)}
                                onSubmit={this.updateHandler(attachment.id)}
                                toggleEdit={this.toggleEditHandler(attachment.id)}
                                updating={attachment.updating}
                            />
                        </li>
                    );
                }

                // Disallow drag and drop on project documents
                const dragAndDropDisabled = !attachment.appendixId;

                return (
                    <Draggable
                        draggableId={attachment.id.toString()}
                        index={idx}
                        isDragDisabled={dragAndDropDisabled || isReadOnly}
                        key={attachment.id}
                    >
                        {(provided) => (
                            <li ref={provided.innerRef} {...provided.draggableProps}>
                                <AttachmentRowItem
                                    attachment={attachment}
                                    deleteHandler={this.deleteHandler(attachment.id)}
                                    downloadHandler={downloadPublicAttachment}
                                    dragHandleProps={
                                        !dragAndDropDisabled && provided.dragHandleProps
                                    }
                                    isEditable={isEditable}
                                    isReadOnly={isReadOnly}
                                    toggleEdit={this.toggleEditHandler(attachment.id)}
                                />
                            </li>
                        )}
                    </Draggable>
                );
            });

        return (
            <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="attachments-table" isDropDisabled={isReadOnly}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <ul className="list-unstyled attachments-table">
                                {AttachmentItems}
                                {provided.placeholder}
                            </ul>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    renderAttachments() {
        if (this.projectAttachments.length === 0) {
            return this.renderNoAttachments('attachments');
        }

        return this.renderTable(this.projectAttachments);
    }

    renderProjectDocuments() {
        const { attachments } = this.props;

        const projectDocuments = attachments.filter((att) => att.type === PROJECT_DOCUMENT);
        if (projectDocuments.length === 0) {
            return this.renderNoAttachments('project documents');
        }

        return this.renderTable(projectDocuments, true);
    }

    renderSubHeader(headerName) {
        return <h5 className="attachment-header">{headerName}</h5>;
    }

    render() {
        const {
            isDocx,
            showComments,
            showProjectDocuments,
            title,
            sectionDescActions,
            isEditable,
            projectSection,
            projectSubsectionsMap,
            description,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        } = this.props;

        const projectSubsectionId = projectSubsectionsMap[BODY].id;

        // Do not display if rendering docx template
        if (isDocx) {
            return null;
        }

        return (
            <div className="col-xs-12 col-md-offset-1 col-md-10 attachment-section">
                <ProjectDetailTitle
                    {...this.projectTitleProps}
                    showComments={showComments}
                    title={title}
                    useManualNumbering={useManualNumbering}
                />
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    isSubsection
                    projectSectionId={projectSection.id}
                    projectSubsectionId={projectSubsectionId}
                    sectionDescription={description[projectSubsectionId]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                {showProjectDocuments && (
                    <>
                        {this.renderSubHeader('Main Documents')}
                        {this.renderProjectDocuments()}
                        {this.renderAttachmentBlock(PROJECT_DOCUMENT, true)}
                        {this.renderSubHeader('Supporting Attachments')}
                    </>
                )}
                {this.renderAttachments()}
                {this.renderAttachmentBlock(OTHER)}
            </div>
        );
    }
}
