import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { ProjectAttachmentBlock } from '../../../../../../components/Forms/ProjectAttachmentBlock';

const { colors } = capitalDesignTokens.foundations;

const {
    OTHER,
    PROJECT_DOCUMENT,
    INTERNAL,
    SIGNED_DOCUMENT,
    SIGNED_DOCUMENT_CERTIFICATE,
    COMPILED_DOCUMENT,
    EXTERNAL_DOCUMENT,
} = attachmentTypesDict;

export const ExhibitsAttachments = ({ disabled, form, project, triggerUpdate }) => {
    return (
        <Box>
            <Box
                sx={{ backgroundColor: 'white', border: `1px solid ${colors.gray200}`, padding: 3 }}
            >
                <ProjectAttachmentBlock
                    attachmentListLabel="Uploaded Exhibits"
                    disabled={disabled}
                    emptyListSubtitle="Add an exhibit above to see it here"
                    emptyListTitle="No exhibits"
                    form={form}
                    formKey={OTHER}
                    hideNoAttachments
                    isOGThemeEnabledForComponents
                    listAttachmentExcludeType={[
                        PROJECT_DOCUMENT,
                        INTERNAL,
                        SIGNED_DOCUMENT,
                        SIGNED_DOCUMENT_CERTIFICATE,
                        COMPILED_DOCUMENT,
                        EXTERNAL_DOCUMENT,
                    ]}
                    projectId={project.id}
                    triggerUpdate={triggerUpdate}
                    uploadAttachmentType={OTHER}
                />
            </Box>
        </Box>
    );
};

ExhibitsAttachments.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.object,
    project: PropTypes.shape({
        id: PropTypes.string,
    }),
    triggerUpdate: PropTypes.func,
};
