import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formValueSelector, getFormSyncErrors } from 'redux-form';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { ATTACHMENTS } from '@og-pro/shared-config/questionnaires';

import {
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { ProjectCreateV2FunctionsContext } from '../../context';
import { UploadDocumentAttachments } from '../../components/Attachments/UploadDocument';

const { EXTERNAL_DOCUMENT } = attachmentTypesDict;

export const ProjectCreateV2UploadDocument = () => {
    const { colors } = capitalDesignTokens.foundations;

    const navigate = useNavigate();
    const { buildRouteFunction, disabled, form, initiateSneakyUpdate, project, showFormErrors } =
        useContext(ProjectCreateV2FunctionsContext);
    const attachments = useSelector((state) => formValueSelector(form)(state, ATTACHMENTS));
    const externalDocument = attachments.find(
        (attachment) => attachment.type === EXTERNAL_DOCUMENT
    );
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    //  eslint-disable-next-line no-underscore-dangle
    const error = formErrors?.externalDocument?._error;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Fill available height
                flex: 1, // Take up remaining space in parent container
            }}
        >
            <Box
                bgcolor="white"
                borderBottom={`1px solid ${colors.gray200}`}
                borderTop={`1px solid ${colors.gray200}`}
            >
                <SectionHeaderViewTitleOnly
                    description="This section is intended to upload an externally drafted document.
                    "
                    title="Upload Document"
                />
            </Box>
            <Box
                sx={{
                    py: 4,
                    px: 3,
                    flex: 1, // This will make the content area expand
                    overflow: 'auto', // In case content overflows
                }}
            >
                {showFormErrors && error && (
                    <Typography color={colors.red700} mt={1}>
                        {error}
                    </Typography>
                )}
                <UploadDocumentAttachments
                    disableDropzone={!!externalDocument}
                    disabled={disabled}
                    form={form}
                    project={project}
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
            <Box
                bgcolor="white"
                borderTop={`1px solid ${colors.gray200}`}
                py={2}
                sx={{
                    mt: 'auto', // This ensures the box sticks to the bottom
                }}
            >
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => navigate(buildRouteFunction('project-properties'))}
                        >
                            Properties
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => navigate(buildRouteFunction('attachments'))}
                        >
                            Exhibits
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
