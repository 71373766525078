import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { SDv2QuestionLogicIcon } from '../../../../components';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    CDSButton,
    CDSButtonGroup,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { SharedTextareaToolbar } from '../../../../../../../components/SDv2/SharedTextareaToolbar';
import { TemplateEditV2FunctionsContext } from '../../../context';
import { getSignaturesTitles } from '../../../constants';
import { form } from '../../../../forms/constants';
import { SignatureForm } from '../../../../../../../components';
import { defaultBlock } from '../../../../../../../components/Forms/SignatureForm/constants';
import { fieldNames } from '../../../../forms/ProjectContentForm/constants';
import { showConfirmationSimpleModal } from '../../../../../../../actions/confirmation';

const { SIGNATURES } = fieldNames;

export const TemplateEditSignatures = () => {
    const dispatch = useDispatch();
    const {
        buildRouteFunction,
        change,
        disabled,
        updateTemplateIfDirty,
        router,
        showFormValidation,
        tagOptions,
        templateVariableOptions,
        templateProject,
        updateTemplate,
    } = useContext(TemplateEditV2FunctionsContext);
    const signatures = useSelector((state) => formValueSelector(form)(state, SIGNATURES));
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);

    const styles = require('./index.scss');

    const title =
        templateProject?.type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled
            ? 'Exhibits'
            : 'Attachments';

    const addSignature = () => {
        dispatch(
            arrayPush(form, SIGNATURES, {
                introduction: '',
                footer: '',
                signaturesBlocks: [[defaultBlock], [defaultBlock]],
            })
        );
        setTimeout(() => {
            updateTemplate();
        }, 1);
    };

    const removeSignature = (index) => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    dispatch(arrayRemove(form, SIGNATURES, index));
                    setTimeout(() => {
                        updateTemplate();
                    }, 1);
                },
                {
                    btnText: 'Delete Signature',
                    text:
                        'This will delete the Signature and all the content defined within. ' +
                        'Are you sure you want to delete this Signature?',
                }
            )
        );
    };

    const signatureIndex = signatures.length ? 0 : -1;
    const signature = signatureIndex > -1 ? signatures[signatureIndex] : null;

    return (
        <Box
            className={styles.container}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Fill available height
                flex: 1, // Take up remaining space in parent container
            }}
        >
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description={getSignaturesTitles(contractPackageCompilerEnabled)[0].description}
                    title={
                        <Box alignItems="center" display="flex" flex={1}>
                            <Box flex={3}>
                                {getSignaturesTitles(contractPackageCompilerEnabled)[0].title}
                            </Box>
                            <Box flex={1}>
                                {!!signature && (
                                    <Box textAlign="right">
                                        <Box>
                                            <SDv2QuestionLogicIcon
                                                // since we have no project section we need to
                                                // have a specially made "entire section" option, which normally
                                                // just adds a logic to the project section
                                                entireSectionOptionOverrides={{
                                                    linkable:
                                                        questionLogicLinkableModelNames.SIGNATURE,
                                                    linkableItem: signature,
                                                    value: `${questionLogicLinkableModelNames.SIGNATURE}.${signature.id}`,
                                                    label: 'Signature tab',
                                                }}
                                                linkable={questionLogicLinkableModelNames.SIGNATURE}
                                                linkableItem={signature}
                                                projectSection={{
                                                    id: signature.id,
                                                    section_type: sectionTypeNames.SIGNATURE,
                                                    title: 'Signature',
                                                }}
                                                usingFakeSection
                                            />
                                        </Box>
                                        <Box>
                                            <CDSButton
                                                className={styles.destroy}
                                                noPadding
                                                onClick={() => removeSignature(signatureIndex)}
                                                qaTag="templateEditor-removeSignature"
                                                size="small"
                                                variant="text"
                                            >
                                                <i className="fa fa-trash" /> Delete Signature
                                            </CDSButton>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    }
                />
            </Box>
            <SharedTextareaToolbar />
            <Box
                className={styles.content}
                sx={{
                    py: 4,
                    px: 3,
                    flex: 1, // This will make the content area expand
                    overflow: 'auto', // In case content overflows
                }}
            >
                {!signature && (
                    <CDSButtonGroup>
                        <CDSButton
                            aria-label="Insert Button"
                            disabled={disabled}
                            onClick={() => addSignature()}
                            qaTag="signatures-insert"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add a Signature
                        </CDSButton>
                    </CDSButtonGroup>
                )}
                {!!signature && (
                    <Box>
                        <SignatureForm
                            {...{
                                change,
                                disabled,
                                form,
                                tagOptions,
                                templateVariableOptions,
                            }}
                            enableQuestionLogics
                            formKey={`${SIGNATURES}.${signatureIndex}`}
                            questionLogicsConfig={{
                                entireSectionOptionOverrides: {
                                    linkable: questionLogicLinkableModelNames.SIGNATURE,
                                    linkableItem: signature,
                                    value: `${questionLogicLinkableModelNames.SIGNATURE}.${signature.id}`,
                                    label: 'Signature tab',
                                },
                                projectSection: {
                                    id: signature.id,
                                    section_type: sectionTypeNames.SIGNATURE,
                                    title: 'Signature',
                                },
                                usingFakeSection: true,
                            }}
                            showValidation={showFormValidation}
                        />
                    </Box>
                )}
            </Box>
            <Box
                className={styles.footer}
                sx={{
                    mt: 'auto', // This ensures the box sticks to the bottom
                }}
            >
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('attachments'));
                            }}
                        >
                            {title}
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('questions-and-automations'));
                            }}
                        >
                            Questions & Automations
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
